
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import marksChart from "./components/marksChart.vue";

interface Subject {
  name: string;
  coef: number;
  mark: number;
  min: string;
  max: string;
}

interface Module {
  name: string;
  coef: number;
  maxMoyenne: string;
  minMoyenne: string;
  subjects: Subject[];
}

interface Bulletin {
  available: boolean;
  moyenne: string;
  maxMoyenne: string;
  minMoyenne: string;
  fullname: string;
  students: number;
  classroomName: string;
  modules: Module[];
  fullName: string;
}

interface Mark {
  mark: number;
  subjectName: string;
  min: string;
  max: string;
}

export default defineComponent({
  name: "Bulletin",
  components: { marksChart },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const route = useRoute();

    const loading = ref(true);
    const type = ref("");
    const bulletin = ref<Bulletin>({} as Bulletin);
    const marksData = ref<Mark[]>([]);

    const available = ref(false);

    const fetchBulletinData = () => {
      const studentID = window.localStorage.getItem("activeStudent");
      const trimester = String(store.getters.currentTrimester);

      const apiUrl = `/bulletin/v2/bt/parent/${type.value}/${studentID}/${trimester}`;

      ApiService.get(apiUrl)
        .then(({ data }) => {
          console.log("API Response:", data);
          bulletin.value = data;

          available.value = !!(
            bulletin.value.modules && bulletin.value.modules.length > 0
          );

          if (available.value) {
            marksData.value = [];
            bulletin.value.modules.forEach((module: Module) => {
              module.subjects.forEach((subject: Subject) => {
                const mark: Mark = {
                  subjectName: subject.name,
                  mark: subject.mark,
                  min: subject.min,
                  max: subject.max,
                };
                marksData.value.push(mark);
              });
            });
          }

          loading.value = false;
        })
        .catch((e) => {
          console.error("Error fetching bulletin data:", e);
          available.value = false;
          loading.value = false;
        });
    };

    watch(
      () => route.name,
      (newRouteName) => {
        switch (newRouteName) {
          case "bulletin":
            type.value = "MP";
            break;
          case "bulletinPilote":
            type.value = "MT";
            break;
          case "bulletinSpecific":
            type.value = "MS";
            break;
          default:
            type.value = "MP";
        }
        loading.value = true;
        fetchBulletinData();
      },
      { immediate: true }
    );

    onMounted(() => {
      setCurrentPageTitle("dashboard.reportSpec");
    });

    const tableRowClassName = ({
      row,
      rowIndex,
    }: {
      row: Mark;
      rowIndex: number;
    }) => {
      if (typeof row.mark == "number" && row.mark < 10) {
        return "danger-row";
      }
      return "";
    };

    return {
      t,
      bulletin,
      loading,
      marksData,
      type,
      tableRowClassName,
      available,
    };
  },
  methods: {
    capitalize(s: string) {
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
  },
});
